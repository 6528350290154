<template>
  <div>
    <b-modal
      id="modal-result-flightv2-search"
      class="modal-result-flightv2-search"
      :title="$t('reservation.searchFlight')"
      centered
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="p-0"
      footer-class="d-flex justify-content-center"
      :size="['md'].includes(appBreakPoint) ? 'reservation-sms' : 'xl'"
      no-close-on-backdrop
      @hide="handleCloseModalSearch"
      @show="showHandle"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        opacity="0.97"
      >
        <div class="bg-image d-flex justify-content-center align-items-center">
          <div
            class="search-flight-modal d-flex flex-column justify-content-start align-items-center my-1"
          >
            <div class="w-100 justify-content-start">
              <SearchSwitchTypeCheckbox
                v-if="!loading"
                :type.sync="searchData.type"
              />
            </div>

            <oneWay
              v-if="searchData.type === 'OW'"
              :search-data.sync="searchData"
              @swap-from-to="swapHandle"
            />

            <roundTrip
              v-if="searchData.type === 'RT'"
              :search-data.sync="searchData"
              :is-result-search="true"
              @swap-from-to="swapHandle"
            />
            <multiCity
              v-if="searchData.type === 'MC'"
              :search-data.sync="searchData"
              :is-result-search="true"
              @swap-from-to="swapHandle"
            />
          </div>
        </div>
      </b-overlay>

      <template #modal-footer="{close}">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center rounded-pill mr-md-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-0 py-50"
          pill
          :disabled="!isActiveSearchButton"
          @click="handleSearchButton"
        >
          <div class="d-flex-center px-25">
            <img
              src="@icons/search.svg"
              alt="Search"
              class="mr-25 ico-bold"
            >
            {{ $t('reservation.searchFlight') }}
          </div>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, BOverlay,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'
import { defaultDeparture, defaultArrival } from '@/constants/flight'
import router from '@/router'

import { convertISODateTime } from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'
import oneWay from '@flightv2/search/one-way.vue'
import roundTrip from '@flightv2/search/round-trip.vue'
import multiCity from '@flightv2/search/multi-city.vue'
import SearchSwitchTypeCheckbox from '@flightv2/search/components/SearchSwitchTypeCheckbox.vue'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,

    oneWay,
    roundTrip,
    multiCity,
    SearchSwitchTypeCheckbox,
  },
  setup(_, { emit }) {
    const { toastError } = useToast()

    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      fetchAirportGroup,
      fetchAirports,
      getAirportGroup,
      // searchClassBooking,
      // searchFlightv2,
    } = useBookingHandle()

    const blankSearchData = {
      adult: 1,
      child: 0,
      infant: 0,
      type: 'OW',
      currency: 'VND',
      numberOfStop: 0,
      flights: [
        {
          startPoint: defaultDeparture,
          endPoint: defaultArrival,
          departDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())),
          returnDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 3)),
        },
      ],
      airlines: [],
      promoCodes: [],
      isSearchClassBooking: false,
      isSearchMonthlyCheapestFare: false,
    }

    const searchData = ref(cloneDeep(blankSearchData))
    const loading = ref(false)
    const isActiveSearchButton = ref(false)

    if (isEmpty(getAirportGroup.value)) { fetchAirportGroup() }

    if (!searchData.value.flights[0].startPoint) {
      searchData.value.flights[0].startPoint = defaultDeparture
    }

    if (!searchData.value.flights[0].endPoint) {
      searchData.value.flights[0].endPoint = defaultArrival
    }

    const showHandle = async () => {
      loading.value = true

      try {
        const query = localStorage.getItem('searchFlight')
        const localData = ref({ ...JSON.parse(query) })

        const flights = await Promise.all(
          localData.value?.flights.map(async flight => {
            const startPointData = await fetchAirports(flight.startPoint)
            const endPointData = await fetchAirports(flight.endPoint)

            return {
              ...flight,
              startDate: convertISODateTime(flight.startDate).dateFilter,
              endDate: convertISODateTime(flight.endDate).dateFilter,
              startPoint: startPointData.data.items[0],
              endPoint: endPointData.data.items[0],
            }
          }),
        )

        searchData.value = {
          ...localData.value,
          flights,
        }
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    function swapHandle(index = 0) {
      const temp = searchData.value.flights[index].startPoint
      searchData.value.flights[index].startPoint = searchData.value.flights[index].endPoint
      searchData.value.flights[index].endPoint = temp
    }

    const searchV2Data = ref(null)

    const defaultSearchClassBookingData = {
      airlines: ['VU'],
      numberOfStop: 0,
      isSearchMonthlyCheapestFare: false,
    }

    const defaultSearchMonthlyCheapestData = {
      airlines: ['VN', 'VJ', 'QH', 'VU'],
      isSearchClassBooking: false,
    }

    watch(() => searchData.value.isSearchClassBooking, val => {
      if (val) {
        searchV2Data.value = {
          airlines: searchData.value.airlines,
          numberOfStop: searchData.value.numberOfStop,
        }

        searchData.value = {
          ...searchData.value,
          ...defaultSearchClassBookingData,
        }

        if (searchData.value.permittedCarriers && searchData.value.permittedCarriers.length > 0) {
          searchData.value.airlines = ['1G']
        }
      } else if (searchV2Data.value && !val) {
        searchData.value = {
          ...searchData.value,
          ...searchV2Data.value,
        }
      }
    })

    watch(() => searchData.value.isSearchMonthlyCheapestFare, val => {
      if (val) {
        searchV2Data.value = {
          airlines: searchData.value.airlines,
        }

        searchData.value = {
          ...searchData.value,
          ...defaultSearchMonthlyCheapestData,
        }
      } else if (searchV2Data.value && !val) {
        searchData.value = {
          ...searchData.value,
          ...searchV2Data.value,
        }
      }
    })

    watch(
      () => searchData.value.flights,
      val => {
        if (val) {
          isActiveSearchButton.value = val.every(item => {
            const sPoint = item?.startPoint?.iata || item?.startPoint
            const ePoint = item?.endPoint?.iata || item?.endPoint
            if (sPoint === ePoint) {
              toastError({
                title:
                  'messagesList["Departure and destination must be difference"]',
              })
              return false
            }
            return true
          })
        }
      },
      { deep: true },
    )

    function modifySearchData(searchData) {
      if (searchData.type === 'OW' || searchData.type === 'RT') {
        searchData.flights.splice(1)
      }
      const payload = { ...JSON.parse(JSON.stringify(searchData)) }
      payload.airlines = searchData.airlines ? searchData.airlines.map(item => item.toUpperCase()) : []

      // eslint-disable-next-line arrow-body-style
      payload.flights = payload.flights.map(item => {
        return {
          ...item,
          startPoint: item.startPoint.iata,
          endPoint: item.endPoint.iata,
        }
      })

      if (payload.airlines.includes('GDS') && searchData.airlines.includes('NDC')) {
        payload.airlines = payload.airlines.filter(item => item !== 'GDS' && item !== 'NDC')
        payload.contentSource = undefined
        if (!payload.airlines.includes('1G')) {
          payload.airlines.push('1G')
        }
      } else if (payload.airlines.includes('GDS')) {
        payload.airlines = payload.airlines.filter(item => item !== 'GDS')
        payload.contentSource = 'GDS'
        if (!payload.airlines.includes('1G')) {
          payload.airlines.push('1G')
        }
      } else if (payload.airlines.includes('NDC')) {
        payload.airlines = payload.airlines.filter(item => item !== 'NDC')
        payload.contentSource = 'NDC'
        if (!payload.airlines.includes('1G')) {
          payload.airlines.push('1G')
        }
      } else {
        payload.contentSource = undefined
      }

      return payload
    }

    function modifySearchClassBooking(data) {
      if (data.type === 'OW' || data.type === 'RT') {
        data.flights.splice(1)
      }

      const payload = {
        // airline: 'VN',
        adult: data.adult,
        child: data.child,
        infant: data.infant,
        type: data.type,
        isSearchMonthlyCheapestFare: data.isSearchMonthlyCheapestFare,
        isSearchClassBooking: data.isSearchClassBooking,
      }

      payload.airlines = data.airlines ? data.airlines.map(item => item.toUpperCase()) : []

      if (searchData.airlines?.includes('1G')) {
        payload.source = '1G'
      } else if (payload.source) {
        delete payload.source
      }

      // eslint-disable-next-line arrow-body-style
      payload.flights = data.flights.map(item => {
        return {
          ...item,
          startPoint: item.startPoint.iata,
          endPoint: item.endPoint.iata,
          departDate: convertISODateTime(item.departDate).dateFilter,
          ...(item.returnDate && { returnDate: convertISODateTime(item.returnDate).dateFilter }),
        }
      })
      return payload
    }

    async function handleSearchButton() {
      const currentPath = router.history.current.name

      const setLocalStorageAndRedirect = async (name, data) => {
        localStorage.setItem('searchFlight', JSON.stringify(data))
        if (currentPath !== name) {
          router.push({ name })
        } else {
          emit('update-search-data')
          this.$bvModal.hide('modal-result-flightv2-search')
        }
      }

      if (searchData.value.isSearchClassBooking) {
        const data = await modifySearchClassBooking(searchData.value)
        if (data.type === 'RT' && !data.flights[0].returnDate) {
          toastError({
            title: 'Vui lòng chọn ngày về!',
          })
        } else {
          await setLocalStorageAndRedirect('apps-flightsV2-class-booking-result', data)
          emit('submitClassBooking', data)
        }
      } else if (searchData.value.isSearchMonthlyCheapestFare) {
        const data = modifySearchData(searchData.value)
        store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSearchFlight`, data)
        await setLocalStorageAndRedirect('apps-flightsV2-cheap-fare-calendar-result', data)
      } else {
        const data = modifySearchData(searchData.value)
        emit('submit', data, false)
        await setLocalStorageAndRedirect('apps-flightsV2-result', data)
      }
    }

    function handleCloseModalSearch() {
      searchData.value = cloneDeep(blankSearchData)
      this.$bvModal.hide('modal-result-flightv2-search')
    }

    return {
      loading,
      showHandle,
      searchData,
      swapHandle,
      modifySearchData,
      handleSearchButton,
      handleCloseModalSearch,
      isActiveSearchButton,
    }
  },
}
</script>

<style lang="scss">
.modal-modify-flight-search-trip.modal-body ::v-deep {
  padding: 0 !important;
}
.bg-image {
  background-image: url('../assets/background-image2.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  // height: calc(70vh - 150px);
  padding-bottom: 0rem;
}

.search-flight-modal {
  background: rgba(154, 154, 154, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 1036px;
  max-width: calc(100% - 24px);
  padding: 10px;
}

@media (max-width: 768px) {
  .search-flight-modal {
    padding: 12px;
  }
}

.card {
  border-radius: 8px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
